export const menuSizeSheet = [
  {
    idActive: 1,
    name: "A4",
    width: "210",
    height: "297",
  },
  {
    idActive: 2,
    name: "Oficio",
    width: "215",
    height: "330",
  },
  {
    idActive: 3,
    name: "A3",
    width: "297",
    height: "420",
  },
  {
    idActive: 4,
    name: "Papelografo",
    width: "594",
    height: "841",
  },
];
