import React from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  IObjectComponents,
  addItemKonva,
  updateActiveIDKonva,
} from "../../core/store/konva-editor/konva-editorSlice";
import { ComponentKonvaItem } from "../../features/editor-konva/editor-konva";
import { KonvaTypeItem } from "../../features/editor-konva/components/global-item-konva/global-item-konva";

const useLoadSavedJob = () => {
  const dispatch = useAppDispatch();

  const handleLoadJobSaved = (data: string) => {
    const dataJob = JSON.parse(data) as IObjectComponents;
    dataJob.components.forEach((item) => {
      if (item.type == KonvaTypeItem.CIRCLE) {
        fillItemCIRCLE(item);
      }
      if (item.type == KonvaTypeItem.RECT) {
        fillItemRECT(item);
      }
      if (item.type == KonvaTypeItem.TRIANGLE) {
        fillItemTRIANGLE(item);
      }
      if (item.type == KonvaTypeItem.TEXT) {
        fillItemTEXT(item);
      }
      if (item.type == KonvaTypeItem.IMAGE) {
        fillItemIMAGE(item);
      }
    });
  };

  const fillItemCIRCLE = (data: ComponentKonvaItem) => {
    dispatch(
      addItemKonva({
        id: data.id,
        type: KonvaTypeItem.CIRCLE,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        fill: data.fill,
        color: data.color,
        stroke: data.stroke,
        sizeStroke: data.sizeStroke,
      } as ComponentKonvaItem)
    );
    dispatch(updateActiveIDKonva(data.id));
  };

  const fillItemRECT = (data: ComponentKonvaItem) => {
    dispatch(
      addItemKonva({
        id: data.id,
        type: KonvaTypeItem.RECT,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        fill: data.fill,
        color: data.color,
        stroke: data.stroke,
        sizeStroke: data.sizeStroke,
      } as ComponentKonvaItem)
    );
    dispatch(updateActiveIDKonva(data.id));
  };

  const fillItemTRIANGLE = (data: ComponentKonvaItem) => {
    dispatch(
      addItemKonva({
        id: data.id,
        type: KonvaTypeItem.TRIANGLE,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        fill: data.fill,
        color: data.color,
        stroke: data.stroke,
        sizeStroke: data.sizeStroke,
      } as ComponentKonvaItem)
    );
    dispatch(updateActiveIDKonva(data.id));
  };
  const fillItemTEXT = (data: ComponentKonvaItem) => {
    dispatch(
      addItemKonva({
        id: data.id,
        type: KonvaTypeItem.TEXT,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        fill: data.fill,
        customFill: data.customFill,
        customFontSize: data.customFontSize,
        customAlign: data.customAlign,
        customFamily: data.customFamily,
        text: data.text,
      } as ComponentKonvaItem)
    );
    dispatch(updateActiveIDKonva(data.id));
  };

  const fillItemIMAGE = (data: ComponentKonvaItem) => {
    dispatch(
      addItemKonva({
        id: data.id,
        type: KonvaTypeItem.IMAGE,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        image: data.image,
        uuid: data.uuid,
      } as ComponentKonvaItem)
    );
    dispatch(updateActiveIDKonva(data.id));
  };

  return {
    handleLoadJobSaved,
  };
};

export default useLoadSavedJob;
