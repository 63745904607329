/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Typography, Modal, Fade, Box, Backdrop, Grid } from "@mui/material";
import {
  getStatusModalSaveJob,
  updateStatusModalQuestionSave,
  updateStatusModalSaveJob,
} from "../../core/store/app-store/appSlice";
import CustomButton from "../custom-button/custom-button";
import { RightArrowAlt } from "@styled-icons/boxicons-regular/RightArrowAlt";
import { useForm } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { customPalette } from "../../config/theme/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import RuleImg from "../../assets/img/rule_icon.png";
import BookImg from "../../assets/img/book_icon.png";
import styled from "styled-components";

import { Toaster, toast } from "react-hot-toast";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { JobSaveForm, JobSaveSchema } from "../../core/models/job-model";
import { usePostAddNewPersonalJobMutation } from "../../core/store/konva-editor/konvaAPI";
import {
  IDataSave,
  getDataSave,
  resetDataKonva,
} from "../../core/store/konva-editor/konva-editorSlice";
import { useNavigate } from "react-router-dom";

const BoxStyle = styled(Box)`
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 20px 25px;
  outline: none;
  background: white;
  border-radius: 20px;
  overflow: hidden;

  > div:nth-child(1) {
    background: ${customPalette.secondaryColor};
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
    height: 100%;
  }
`;

const WrapperBookImg = styled.img`
  position: absolute;
  bottom: 10px;
  left: -60px;
  width: 150px;
  opacity: 0.2;
`;
const WrapperRuleImg = styled.img`
  position: absolute;
  top: 0;
  right: -80px;
  width: 200px;
  opacity: 0.2;
`;

const FormContainer = styled.div`
> div:nth-child(1), div:nth-child(2), div:nth-child(3){
  justify-content: center;
    flex-direction: column;
    align-items: left;
    display: flex;
    padding 8px 15px;
    
    > input {
      box-shadow: 0px 4px 8px 5px rgba(198, 198, 198, 0.25);
      border: 1px solid #001C46;
      background-color: white;
      border-radius: 12px;
      padding: 8px 14px;
      outline: none;
      width: 100%;
    }
  }
  
  > div:nth-child(3){
    text-align: right;
    padding-right: 15px;
  }
`;
const ContainerOldPassStyle = styled.div<{ errorStyle: boolean }>`
  > input {
    border: ${(p) =>
      p.errorStyle ? "1px solid red !important" : "1px solid #001C46;"};
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: red;
  margin-top: 6px;
`;

const WrapperClose = styled.div`
  position: absolute;
  cursor: pointer;
  height: 20px;
  width: 20px;
  right: 28px;
  top: 20px;

  > svg {
    width: 25px;
    height: 25px;
    color: red;
  }
`;

const ModalSaveJob: React.FC = () => {
  const [addNewJob, resultAddJob] = usePostAddNewPersonalJobMutation();
  const isStatus = useAppSelector(getStatusModalSaveJob);
  const dataCurrentJob = useAppSelector(getDataSave);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<JobSaveForm>({
    resolver: yupResolver(JobSaveSchema),
    defaultValues: {
      nameJob: "",
    },
  });

  const {
    handleSubmit: submitWrapper,
    formState: { errors },
    register,
  } = methods;

  const handleSubmit = React.useCallback(
    (data: any) => {
      addNewJob({
        data: dataCurrentJob.data,
        name: data.nameJob,
        preview: dataCurrentJob.preview,
        sheets: dataCurrentJob.sheets,
        userId: dataCurrentJob.userId,
        uuid: dataCurrentJob.uuid,
      } as IDataSave)
        .unwrap()
        .catch((error) => console.log(error));
    },
    [dataCurrentJob]
  );

  React.useEffect(() => {
    if (resultAddJob.isSuccess) {
      toast.success("Trabajo guardado con éxito");
      setTimeout(() => {
        dispatch(resetDataKonva());
        dispatch(updateStatusModalQuestionSave(false));
        dispatch(updateStatusModalSaveJob(false));
        navigate("/dashboard/guardados");
      }, 1000);
    }
  }, [resultAddJob.isSuccess]);

  return (
    <>
      <Toaster />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isStatus}
        onClose={() => dispatch(updateStatusModalSaveJob(false))}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isStatus}>
          <BoxStyle>
            <div />
            <WrapperBookImg src={BookImg} />
            <WrapperRuleImg src={RuleImg} />
            <WrapperClose
              onClick={() => dispatch(updateStatusModalSaveJob(false))}
            >
              <CloseOutline />
            </WrapperClose>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginBottom={2}
            >
              <Typography
                variant="h5"
                component="h5"
                fontWeight="600"
                textAlign={"center"}
                color={customPalette.secondaryColor}
              >
                Guardar nuevo trabajo
              </Typography>
            </Grid>
            <Grid item xs={12} position="relative" zIndex={2}>
              <FormContainer>
                <ContainerOldPassStyle
                  errorStyle={!!(errors.nameJob as any)?.message}
                >
                  <Typography
                    variant="caption"
                    component="label"
                    textAlign="left"
                    mb={1}
                  >
                    Nombre del trabajo
                  </Typography>
                  <input
                    placeholder="Nombre del trabajo"
                    type="text"
                    required
                    {...register("nameJob")}
                  />
                  {!!(errors.nameJob as any)?.message && (
                    <ErrorMessage>{errors?.nameJob?.message}</ErrorMessage>
                  )}
                </ContainerOldPassStyle>
              </FormContainer>
            </Grid>
            <Grid
              item
              xs={12}
              position="relative"
              zIndex={2}
              justifyContent="center"
              alignItems="center"
              flexWrap={"wrap"}
              display={"flex"}
              columnGap={1}
              rowGap={1}
              mt={3}
            >
              <CustomButton
                title="Cancelar"
                style="SECONDARY"
                borderStyle="OUTLINE"
                action={() => dispatch(updateStatusModalSaveJob(false))}
                customStyle={`
                  padding: 8px 10px;
                  width: 46%;
                `}
              />
              <CustomButton
                title="Guardar"
                style="SECONDARY"
                borderStyle="NONE"
                Icon={RightArrowAlt}
                isLoading={resultAddJob.isLoading}
                action={submitWrapper(handleSubmit)}
                customStyle={`
                      border-color: white;
                      color: white
                      padding: 8px 10px;
                      width: 46%;
                    `}
              />
            </Grid>
          </BoxStyle>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalSaveJob;
