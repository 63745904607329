import React from "react";
import { Layer, Rect } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getActiveGlobalSheet,
  getCanvasHeight,
  getCanvasWidth,
  getConfigStageZoom,
  getGlobalCoordLayer,
  getSizeGlobalSheet,
  updateActiveIDKonva,
  updateGlobalCoordLayer,
  updateStageZoom,
} from "../../../../core/store/konva-editor/konva-editorSlice";
import { breakpoints } from "../../../../constants/breakpoints";

interface IOwnProps {
  refLayer: any;
  children: any;
}
const LayerEditor: React.FC<IOwnProps> = ({ refLayer, children }) => {
  const sizeGlobalSheet = useAppSelector(getSizeGlobalSheet);
  const activeSheet = useAppSelector(getActiveGlobalSheet);
  const canvasWidth = useAppSelector(getCanvasWidth);
  const canvasHeight = useAppSelector(getCanvasHeight);
  const globalCoordLayer = useAppSelector(getGlobalCoordLayer);
  const globalValueZoom = useAppSelector(getConfigStageZoom);
  const heightCalc =
    window.innerHeight / sizeGlobalSheet[activeSheet - 1][1] - 0.3;
  const [layerPDFWidth, setLayerPDFWidth] = React.useState(
    sizeGlobalSheet[activeSheet - 1][0] * heightCalc
  );
  const [layerPDFHeight, setLayerPDFHeight] = React.useState(
    sizeGlobalSheet[activeSheet - 1][1] * heightCalc
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // const valueReduce = window.innerWidth < breakpoints.tabletSValue ? 0.8 : 0.3;
    // const heightCalc = window.innerHeight / sizeGlobalSheet[activeSheet - 1][1] - valueReduce;
    setLayerPDFWidth(sizeGlobalSheet[activeSheet - 1][0]);
    setLayerPDFHeight(sizeGlobalSheet[activeSheet - 1][1]);
    // dispatch(
    //   updateGlobalCoordLayer({
    //     x: (canvasWidth - sizeGlobalSheet[activeSheet - 1][0]) / 2,
    //     y: (canvasHeight - sizeGlobalSheet[activeSheet - 1][1]) / 2,
    //     height: 0,
    //     width: 0,
    //   })
    // );

    dispatch(
      updateGlobalCoordLayer({
        x: 10,
        y: 0,
        height: 0,
        width: 0,
      })
    );

    const initZoom =
      window.innerHeight / 297 -
      (canvasWidth - sizeGlobalSheet[activeSheet - 1][0] > 150 ? 0.5 : 0.8);

    const newX =
      -globalCoordLayer.x * initZoom +
      canvasWidth / 2 -
      sizeGlobalSheet[activeSheet - 1][0] / 2;
    const newY =
      -globalCoordLayer.y * initZoom +
      canvasHeight / 2 -
      sizeGlobalSheet[activeSheet - 1][1] / 2;

    // console.log("INIT VALUES LAYER STAGE -> ", {
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight,
    //   initZoom,
    //   canvasWidth,
    //   canvasHeight,
    //   layerPDFWidth,
    //   layerPDFHeight,
    //   globalCoordLayer,
    //   newX,
    //   newY,
    //   layerWidth: sizeGlobalSheet[activeSheet - 1][0],
    //   layerHeight: sizeGlobalSheet[activeSheet - 1][1],
    //   layerRef: refLayer,
    // });

    dispatch(
      updateStageZoom({
        scale: initZoom,
        x: newX - (newX - sizeGlobalSheet[activeSheet - 1][0]) / 2,
        y: 25,
      })
    );
    // setLayerPDFWidth(sizeGlobalSheet[activeSheet - 1][0] * heightCalc);
    // setLayerPDFHeight(sizeGlobalSheet[activeSheet - 1][1] * heightCalc);
  }, [activeSheet]);

  window.addEventListener("resize", () => {
    // const valueReduce = window.innerWidth < breakpoints.tabletSValue ? 0.8 : 0.3;
    // const heightCalc = window.innerHeight / sizeGlobalSheet[activeSheet - 1][1] - valueReduce;
    // setLayerPDFWidth(sizeGlobalSheet[activeSheet - 1][0] * heightCalc);
    // setLayerPDFHeight(sizeGlobalSheet[activeSheet - 1][1] * heightCalc);
  });

  return (
    <Layer ref={refLayer} width={layerPDFWidth} height={layerPDFHeight}>
      <Rect
        x={globalCoordLayer.x}
        y={globalCoordLayer.y}
        width={layerPDFWidth}
        height={layerPDFHeight}
        fill={"white"}
        shadowColor="#797979e6"
        shadowBlur={12}
        draggable={false}
        onMouseDown={() => {
          dispatch(updateActiveIDKonva(""));
        }}
      />
      {children}
    </Layer>
  );
};

export default LayerEditor;
