import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActions, Skeleton } from "@mui/material";
import styled from "styled-components";
import { Trash3 } from "@styled-icons/bootstrap/Trash3";
import { Edit } from "@styled-icons/fluentui-system-filled/Edit";
import { customPalette } from "../../config/theme/theme";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { updateStatusModalLogin } from "../../core/store/app-store/appSlice";
import useDataUser from "../../utils/hooks/use-data-user";
import {
  resetDataKonva,
  updateInfoJobSaved,
  updateIsLoadingDataSave,
} from "../../core/store/konva-editor/konva-editorSlice";
import useLoadSavedJob from "../../utils/hooks/use-load-saved-job";
import { usePostDeleteJobByIdMutation } from "../../core/store/konva-editor/konvaAPI";
import { Toaster, toast } from "react-hot-toast";

const CustomButtonStyle = styled.div`
  background: ${customPalette.secondaryColor};
  color: white;
  padding: 6px 10px;
  border-radius: 20px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  > svg {
    width: 100%;
    max-width: 18px;
  }
`;

const CustomCardActions = styled(CardActions)`
  > div:nth-child(1) {
    width: 80%;
    transition: 0.5s;
  }
  > div:nth-child(2) {
    width: 20%;
    overflow: hidden;
    transition: 0.5s;

    > span {
      display: none;
    }

    :hover {
      width: 50%;

      > span {
        display: block;
      }
    }
  }
`;

interface IOwnProps {
  id: number;
  uuid: string;
  image: string;
  name: string;
  dataSaved: string;
  onRefreshListData: () => void;
}
const CardSaved: React.FC<IOwnProps> = ({
  id,
  uuid = "",
  image,
  name,
  dataSaved,
  onRefreshListData,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [statusLoading, setStatusLoading] = React.useState(true);
  const [removeJob, resultRemoveJob] = usePostDeleteJobByIdMutation();

  const handleView = () => {
    // Acción al darle cargar o visualizar un trabajo guardado
  };

  const handleLoaded = () => setStatusLoading(false);

  const { handleGetToken } = useDataUser();

  const handleDelete = () => {
    removeJob(id)
      .unwrap()
      .catch((error) => console.log(error));
  };

  const { handleLoadJobSaved } = useLoadSavedJob();

  const handleEdit = React.useCallback(() => {
    const user = handleGetToken();
    if (user.token) {
      dispatch(resetDataKonva());
      dispatch(
        updateInfoJobSaved({
          id: id,
          uuid: uuid,
          data: dataSaved,
          isActiveJobLoad: true,
        })
      );
      dispatch(updateIsLoadingDataSave(true));
      handleLoadJobSaved(dataSaved);
      setTimeout(() => {
        navigate("/editor");
      }, 1000);
    } else {
      dispatch(updateStatusModalLogin(true));
    }
  }, [image]);

  React.useEffect(() => {
    if (resultRemoveJob.isSuccess) {
      toast.success("Trabajo eliminado con éxito");
      onRefreshListData();
    }
  }, [resultRemoveJob.isSuccess]);

  return (
    <>
      <Toaster />
      <Card
        sx={{
          width: "100%",
          maxWidth: 345,
          margin: "auto",
          borderRadius: "20px",
          boxSizing: "border-box",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          transition: "0.4s",
          ":hover": {
            transform: "scale(1.015)",
          },
        }}
      >
        <CardMedia
          component="img"
          image={image}
          sx={{ maxHeight: "225px", objectPosition: "top", cursor: "pointer" }}
          onLoad={handleLoaded}
          onClick={handleView}
        />
        {statusLoading && (
          <Skeleton variant="rounded" width={"100%"} height={150} />
        )}
        <CardContent onClick={handleView} sx={{ cursor: "pointer" }}>
          <Typography gutterBottom variant="h6" component="h6" fontWeight={600}>
            {name}
          </Typography>
        </CardContent>
        <CustomCardActions sx={{ width: "100%", justifyContent: "center" }}>
          {statusLoading && (
            <>
              <Skeleton variant="rounded" width={"70%"} height={40} />
              <Skeleton variant="rounded" width={"30%"} height={40} />
            </>
          )}
          {!statusLoading && (
            <>
              <CustomButtonStyle onClick={handleEdit}>
                <Typography variant="caption" component="span">
                  Editar
                </Typography>
                <Edit />
              </CustomButtonStyle>
              <CustomButtonStyle onClick={handleDelete}>
                <Typography variant="caption" component="span">
                  Eliminar
                </Typography>
                <Trash3 />
              </CustomButtonStyle>
            </>
          )}
        </CustomCardActions>
      </Card>
    </>
  );
};

export default CardSaved;
