export const listPlans = [
  {
    name: "BASIC",
    price: "Free",
    editor: "Acceso parcial al Editor",
    text: "Acceso a fuentes gratuitas de texto",
    images: "Solo en JPG",
    arturito: "Hasta 10 consultas con Arturito",
    formats: "Descargas en A4 con marca de agua",
    downloads: "5 descargas/mes",
    support: "Sin soporte",
  },
  {
    name: "MEDIUM",
    price: "19.90",
    editor: "Acceso parcial al Editor",
    text: "Acceso a fuentes básicas de texto",
    images: "Solo en JPG y PNG",
    arturito: "Hasta 30 consultas con Arturito",
    formats: "Descargas en A4, A3 y Oficio",
    downloads: "50 descargas",
    support: "Soporte comercial",
  },
  {
    name: "PREMIUM",
    price: "29.90",
    editor: "Acceso completo al Editor",
    text: "Acceso a fuentes premium de texto",
    images: "Solo en JPG, PNG y JPEG",
    arturito: "Consultas ilimitadas con Arturito",
    formats: "Descargas en A4, A3 y Oficio",
    downloads: "100 descargas",
    support: "Soporte 24/7",
  },
  {
    name: "PLATINIUM",
    price: "39.90",
    editor: "Acceso completo al Editor",
    text: "Acceso a fuentes premium de texto",
    images: "Solo en JPG, PNG y JPEG",
    arturito: "Consultas ilimitadas con Arturito",
    formats: "Descargas en A4, A3 y Oficio",
    downloads: "100 descargas",
    support: "Soporte 24/7",
  },
];
